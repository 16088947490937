
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import router from "@/router";
export default defineComponent({
  name: "Account",
  setup() {
    const accountData = ref({});
    axios
      .get("/M/User/Account", {
        params: {
          validate: true,
        },
      })
      .then((res) => {
        if (res.data.success) {
          accountData.value = res.data.obj;
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        Toast({
          type: "error",
          title: err.message,
        });
      });
    const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
    function outLogin() {
      localStorage.removeItem("token");
      clearUserInfo();
      router.push({
        name: "Index",
      });
    }
    return {
      accountData,
      outLogin,
    };
  },
});
